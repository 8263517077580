<template>
  <div class="cont-scontrino">
    <table class="table table-striped" id="scontrino">
      <thead>
        <th class="text-start">Prodotto</th>
        <th class="text-center">Qnt</th>
        <th class="text-end">Prezzo</th>
      </thead>
      <tbody>
        <tr v-if="prodsReceipt.length > 0" v-for="(prodotto,index) in prodsReceipt" :key="prodotto.id">
          <td style="width: 160px;">
            <template v-if="prodotto.type !== 'multivariant'">
              <p class="product">{{prodotto.description}}</p>
            </template>
            <template v-else>
              <p class="product">{{prodotto.description || prodotto.variant.variantname }}</p>
            </template>
          </td>
          <td>
            <div class="input-group">
              <button v-on:click="changeQnt(index,'-')" class="btn btn-sm btn-dark btn-qnt">-</button>
              <input readonly v-model="prodotto.qnt" class="form-control form-control-sm input-qnt">
              <button v-on:click="changeQnt(index,'+')" class="btn btn-sm btn-dark btn-qnt">+</button>
            </div>
          </td>
          <td>
            <p class="price">{{ prodotto.discountprice ? formatPrice(prodotto.discountprice * prodotto.qnt) : formatPrice(prodotto.price * prodotto.qnt) }} €</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row button">
    <div class="col-5">
      <h3 class="float-start">TOTALE</h3>
    </div>
    <div class="col-7">
      <h3 class="float-end"> {{ formatPrice(this.total) || 0 }} €</h3>
    </div>
    <div class="col">
      <button  type="button" class="delete float-end btn btn-large btn-danger" @click="emptyReceipt()">Cancella</button>
    </div>
    <div class="col">
      <ReceiptAlert/>
    </div>
  </div>
</template>

<script>
import ReceiptAlert from "@/components/ReceiptAlert";

export default {
  name: "Scontrino",
  components: {
    ReceiptAlert
  },
  computed: {
    prodsReceipt() {
      return this.$store.getters.getProdsReceipt;
    },
    total() {
      return this.$store.getters.getTotal;
    }
  },
  methods: {
    emptyReceipt() {
      this.$store.commit('emptyReceipt');
      this.$store.commit('setChange', 0);
      this.$store.commit('setExChange', false);
      this.$store.commit('setProdsChange',[]);
      let xml = '<printerCommand><clearText operator="1" /><resetPrinter operator="1" /></printerCommand>';
      this.$store.dispatch("sendData", {xml : xml, async : true});
    },
    formatPrice(value) {
      return parseFloat(value).toFixed(2).toString().replace(".", ",");
    },
    changeQnt(index,action){
      this.$store.dispatch("setQntReceipt",{index,action});
    }
  }
}
</script>

<style scoped>

table th{
  padding: 8px;
}

thead {
  border-bottom: 1px #d2d2d2 solid;
}

.price {
  text-align: end;
  font-size: 15px;
}

.product {
  text-align: start;
  font-size: 13px;
}

.cont-scontrino {
  border: 1px solid #d2d2d2;
  margin-top: 10px;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  height: 500px;
}

.button {
  margin-top: 10px;
}

.delete {
  width: 100%;
  border-radius: 0px;
}

.btn-qnt{
  width: 30px;
  height: 30px;
  color: #ffffff;
}

.input-qnt{
  text-align: center;
  width: 20px;
}
</style>