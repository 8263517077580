import Vuex from "vuex";
import getters from '@/services/getters';
import mutations from '@/services/mutations';
import actions from '@/services/actions';
export default new Vuex.Store({
    state: {
        message: '',
        showAlertStock: false,
        //spinner generale
        spinner: true,
        menu: false,
        total: 0,
        change: 0,//resto
        exchange: false,//indica il cambio merce
        prodsChange : [],//array dei prodotti da cambiare
        products: [],
        //categories: [],
        prodsReceipt: [],
        //idCategory: null,//filtro per i prodotti
        transfers: [],
        sales: [],
        //prodotti nel refund
        prodsRefund: [],
        //in base al tipo di operazione chiamo l'endpoint dedicato
        refund: false,
        //lo devo passare al endpoint doRefund
        idSale: null,
        toggle: false,
        socket : null,//socket dello scanner
        sockconn : false, //vedo se la socket è connessa o no,
        deviceconn : false, //vedo se il device è connesso o no
        searchstring : "", //lettura da scanner o ricerca prodotto,
        searchsale : "",
        sellsAlert : false, //variabile di stato per fare aprire le vendite,
        totalsales : 0,
        totBikiniSold : 0, //totale bikini venduti
        totBikiniStock: 0, //totale bikini rimennti nel punto vendita
        showModalDetails: false,
        productDetail: null,
        //variabile per distinguere quando cerco un prodotto tramite pistola o tramite search quando faccio la getProducts
        deviceProd: false
    },
    getters,
    mutations,
    actions,
    modules: {}
});
