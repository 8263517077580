<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Dettaglio vendita</h5>
          <button v-on:click="toggleModal()" type="button" class="btn-close"/>
        </div>
          <div class="modal-body">
            <div class="receipt-top"></div>
            <div class="receipt">
              <div class="text-center title-receipt">BKL Srls</div>
              <div class="text-center">{{ sale?.salespoint.name }}</div>
              <div class="text-center subtitle">{{ sale?.salespoint.street }}, {{ sale?.salespoint.streetnumber }}</div>
              <div class="text-center subtitle">{{ sale?.salespoint.zipcode }}, {{ sale?.salespoint.city }}
                ({{ sale?.salespoint.prov }})
              </div>
              <div class="text-center subtitle">P.IVA 0172839300</div>
              <table>
                <tr v-if="sale?.oldIdSale !== undefined">
                  <td class="text-center changetext" colspan="2" v-if="isNaN(sale?.oldIdSale)">
                    CAMBIO CON DOCUMENTO N° {{ sale?.old_sale.zRepNumber }} - {{ sale?.old_sale.number }} - {{sale?.old_sale.timestamp}}
                  </td>
                  <td class="text-center changetext" colspan="2" v-else>
                    CAMBIO CON ORDINE ONLINE N° {{sale?.oldIdSale}}
                  </td>
                </tr>
                <tr>
                  <td class="text-start"></td>
                  <td class="text-end">EURO</td>
                </tr>
                <tr v-for="(prod,index) in sale?.products" :key="index">
                  <td class="text-start" v-if="prod.qnt > 1">x {{ prod.qnt }} {{ prod.description }}</td>
                  <td class="text-start" v-else>{{ prod.description }}</td>
                  <td class="text-end">{{ formatPrice(prod.price * prod.qnt) }}</td>
                </tr>
                <tr v-if="sale?.total < 0">
                  <td class="text-start">BUONO SCONTO</td>
                  <td class="text-end">{{ formatPrice(sale?.total * -1) }}</td>
                </tr>
                <tr v-if="sale?.voucher">
                  <td class="text-start">VOUCHER {{sale?.voucher}}</td>
                  <td class="text-end">-{{ formatPrice(sale?.discount)}}</td>
                </tr>
                <tr v-else-if="sale?.discount > 0">
                  <td class="text-start">SCONTO</td>
                  <td class="text-end">-{{ formatPrice(sale?.discount) }}</td>
                </tr>
                <tr v-if="sale?.automaticVoucher">
                  <td class="text-start">VOUCHER CAMBIO</td>
                  <td class="text-end">{{sale?.automaticVoucher}}</td>
                </tr>
                <tr>
                  <td class="text-start"><h5 class="total">TOTALE EURO</h5></td>
                  <td class="text-end"><h5 class="total">{{ formatPrice(sale?.total) }}</h5></td>
                </tr>
                <template v-if="sale?.payment">
                  <tr>
                    <td class="text-start">{{sale?.payment.toUpperCase()}}</td>
                    <td class="text-end" v-if="sale?.payment === 'contanti'">{{ formatPrice(sale?.total + sale?.change) }}</td>
                    <td class="text-end" v-else>{{ formatPrice(sale?.total) }}</td>
                  </tr>
                </template>
                <template v-else>
                  <template v-if="sale?.payments.card.active !== undefined ? sale?.payments.card.active : sale?.payments.card">
                    <tr>
                      <td class="text-start">
                        CARTA
                      </td>
                      <td class="text-end">
                        <span v-if="sale?.payments.card.active !== undefined">{{ formatPrice(parseFloat(sale?.payments.card.value))}}</span>
                        <span v-else>{{ formatPrice(parseFloat(sale?.payments.card))}}</span>
                      </td>
                    </tr>
                  </template>
                  <template v-if="sale?.payments.bancomat.active !== undefined ? sale?.payments.bancomat.active : sale?.payments.bancomat">
                    <tr>
                      <td class="text-start">
                        BANCOMAT
                      </td>
                      <td class="text-end">
                        <span v-if="sale?.payments.bancomat.active !== undefined">{{ formatPrice(parseFloat(sale?.payments.bancomat.value))}}</span>
                        <span v-else>{{ formatPrice(parseFloat(sale?.payments.bancomat))}}</span>
                      </td>
                    </tr>
                  </template>
                  <template v-if="sale?.payments.cash.active !== undefined ? sale?.payments.cash.active : sale?.payments.cash">
                    <tr>
                      <td class="text-start">
                        CONTANTI
                      </td>
                      <td class="text-end">
                        <span v-if="sale?.payments.cash.active !== undefined">{{ formatPrice(parseFloat(sale?.payments.cash.value) + parseFloat(sale?.change))}}</span>
                        <span v-else>{{ formatPrice(parseFloat(sale?.payments.cash))}}</span>
                      </td>
                    </tr>
                  </template>
                </template>
                <tr>
                  <td class="text-start">RESTO</td>
                  <td v-if="sale?.change" class="text-end">{{ formatPrice(sale?.change) }}</td>
                  <td v-else class="text-end">0,00</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td class="text-start">{{ sale?.localtime }} - {{ sale?.timestamp }}</td>
                  <td class="text-end" v-if="sale?.zRepNumber === '0001' && sale?.number === '0000'">SNF: 0001 - 0000
                  </td>
                  <td class="text-end" v-else>{{ sale?.zRepNumber }}-{{ sale?.number }}</td>
                </tr>
              </table>
            </div>
            <div class="receipt-bottom"></div>
          </div>

        <div class="modal-footer">
          <button class="btn btn-dark" type="button" v-on:click="toggleModal()">Indietro</button>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-sm btn-success mb-2" v-on:click="toggleModal()">
    Vedi
  </button>
</template>

<script>

import axios from "axios";

export default {
  name: "ShowReceipt",
  props: {
    visible: Boolean,
    idSale: String
  },
  data() {
    return {
      open: this.visible,
      sale: null
    }
  },
  methods: {
    toggleModal() {
      this.open = !this.open
      this.getSale();
    },
    formatPrice(value) {
      return Number(value).toFixed(2).toString().replace(".", ",");
    },
    async getSale() {
      this.$store.commit("setSpinner", true);
      axios.get("/sales/" + this.idSale).then((response) => {
        this.sale = response.data;
        console.log("this.sale", this.sale)
        this.$store.commit("setSpinner", false);
      }).catch((error) => {
        this.$store.commit("setSpinner", false);
        console.log(error);
      });
    }
  },
}
</script>

<style scoped>
.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.receipt {
  width: 100%;
  padding: 15px 25px 15px 25px;
  font-family: Roboto;
  color: #000;
  margin: 0 auto;
  max-width: 370px;
  overflow: hidden;
  background-image: url(../../public/images/receipt_body.png);
  background-size: 100%;
  background-repeat: repeat-y;
}

.receipt .subtitle {
  font-size: 13px;
}

.receipt .total {
  line-height: 20px !important;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.receipt-top, .receipt-bottom {
  width: 100%;
  margin: 0 auto;
  max-width: 370px;
  height: 17px;
  background-size: 100% !important;
}

.receipt-top {
  background-image: url(../../public/images/receipt-top.png);
  background-repeat: no-repeat;
}

.receipt-bottom {
  background-image: url(../../public/images/receipt_bottom.png);
  background-repeat: no-repeat;
}

.title-receipt {
  font-size: 20px;
}

.receipt table {
  margin-top: 20px;
  width: 100%;
  border: none;
  font-weight: normal;
}

.receipt table tr td, .receipt table tr th {
  line-height: 20px !important;
  border: none;
  padding: 0;
}

.changetext {
  font-weight: bold;
  font-size: 12px;
}
</style>

