<template>
  <div>
      <input class="form-control"
             type="text"
             placeholder="Cerca prodotto"
             name="search"
             v-model="searchTerm"
             v-on:keyup="searchProducts"
             autocomplete="off">
      <ul class="boxresults" v-if="productsSelect.length">
        <li v-for="prodsel in productsSelect">
            <a href="javascript:void(0);" v-on:click="addItemSel(prod,prodsel)">
              {{ prodsel.text }}
            </a>
        </li>
      </ul>
    </div>
</template>
<script>
import axios from "axios";
//import {ref} from 'vue';

export default {
  name: "AutocompleteSearch",
  computed: {
    prodsChange(){
      return this.$store.getters.getProdsChange;
    }
  },
  props: {
    prod : Object,
    index: String
  },
  /*
  setup() {
    let searchTerm = ref('');
    return {
      searchTerm
    }
  },
   */
  data() {
    return {
      boxresults : false,
      productsSelect : [],
      searchTerm : ""
    }
  },
  methods: {
    async searchProducts() {
      this.productsSelect = [];
      if(this.searchTerm.length >= 2) {
        axios.get("/products/search?term=" + this.searchTerm + "&idsalespoint=" + localStorage.getItem('idSalesPoint')).then((response) => {
          this.productsSelect = response.data;
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    showResults(){
      this.productsSelect = [];
      this.boxresults = true;
    },
    hideResults(){
      this.productsSelect = [];
      this.boxresults = false;
    },
    addItemSel(prod,prodchange){//prodotto selezionato
      console.log(prodchange);
      if (prodchange.qnt <= 0) {
        this.$emit('showModal');
      }
      let newprodsChange = this.prodsChange;
      let prodName = prodchange.text;
      prodchange = JSON.parse(prodchange.id);
      newprodsChange[this.index] = prodchange;
      this.$store.commit("setProdsChange",newprodsChange);
      this.boxresults = false;
      this.searchTerm = prodName;
      this.productsSelect = [];
      //alert(JSON.stringify(this.prodsChange));
    },
  },
}
</script>

<style scoped>

input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dedede;
}

.wrapsearch input {
  border-radius: 0;
  padding: 10px;
  font-size: 18px;
}

.boxresults {
  list-style-type: none;
  padding: 10px;
  overflow-y: scroll;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
}

.boxresults ul li {
  padding: 8px 0 8px 0;
  width: 100%;
  border-top: 1px solid #dedede;
}

.boxresults ul li a {
  color: #000;
}

</style>