<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Trasferimenti</h5>
          <button @click="toggleTransfers" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <form @submit.prevent="getTransfers">
                <div class="input-group mb-3">
                  <input type="text" class="carcaInput form-control" placeholder="Cerca trasferimenti" v-model="search">
                  <div class="input-group-append">
                    <button class="btn btn-danger" type="button" @click="deleteSearch">
                      Elimina
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button class="cerca btn btn-dark" type="submit">Cerca</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="transfers" v-if="transfers.length">
                <div class="table-responsive">
                  <table class="table table-light">
                    <thead>
                      <th>Numero</th>
                      <th>Data</th>
                      <th>Da/a</th>
                      <th>Stato</th>
                      <th>Note</th>
                      <th>Azioni</th>
                    </thead>
                    <tbody>
                      <tr v-for="trans in transfers">
                        <td>
                          {{trans.number}}
                        </td>
                        <td>
                          {{trans.localtime}}
                        </td>
                        <td>
                          {{trans.spfrom}} -> {{trans.spto}}
                        </td>
                        <td>
                          <span v-if="trans.status === 'completed'" class="badge bg-success">{{trans.status}}</span>
                          <span v-else class="badge bg-warning">{{trans.status}}</span>
                        </td>
                        <td>
                          {{trans.notes ? trans.notes : '-'}}
                        </td>
                        <td class="actions">
                            <a target="_blank" :href="baseurl + '/transfers/pdf/' + trans.id" class="btn btn-sm btn-dark">Dettaglio</a>
                            <button class="btn btn-sm btn-success" v-if="trans.status !== 'completed'" v-on:click="confirmTransfer(trans.id)">Approva</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else class="alert alert-info text-center">
                Non ci sono trasferimenti
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button @click="toggleTransfers" class="btn btn-large btn-dark">Trasferimenti</button>
</template>

<script>
import {BASE_URL} from "@/constants/constants";
export default {
  name: "TransfersAlert",
  data() {
    return {
      open: false,
      search : "",
      baseurl : BASE_URL
    }
  },
  computed: {
    transfers() {
      return this.$store.getters.getTransfers;
    }
  },
  methods: {
    deleteSearch() {
      this.search = "";
      this.$store.dispatch("getTransfers")
    },
    getTransfers() {
      this.$store.dispatch("getTransfers", {"search": this.search})
    },
    toggleTransfers() {
      if(!this.open) {
        this.$store.dispatch('getTransfers');
      }
      this.open = !this.open;
    },
    confirmTransfer(id){
      this.$store.dispatch("commitTransfer", {"idtransfer": id})
    }
  }
}
</script>

<style scoped>
td {
  vertical-align: middle;
}
.actions{
  vertical-align: middle;
}
.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.transfers{
  width: 100%;
  overflow: auto;
  max-height: 400px;
}

table{
  text-align: center;
  font-weight: bold;
}

.actions .btn, .actions a{
  display: inline-block !important;
}
</style>