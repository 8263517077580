<template>
  <div class="modal" :class="{show:open}">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Gestione reso/cambio</h5>
          <button v-on:click="toggleModal()" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="text-lg-start">
            <div class="row" v-show="showAlertRefund">
              <div class="alert alert-danger alert-dismissible">
                <strong>Attenzione</strong> Il prodotto non ha disponibilità a magazzino.
                <button v-on:click="showAlertRefund = false" type="button" class="btn-close"></button>
              </div>
            </div>
            <div class="row mb-4">
              <h6><b>CAMBIO:</b> seleziona la casella a sinistra e seleziona poi il prodotto da cambiare, infine clicca su "Effettua cambio"</h6>
              <h6><b>RESO:</b> seleziona il prodotto sulla casella a sinistra e clicca su "Stampa documento di reso"</h6>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <th></th>
                      <th>
                        Prodotto venduto
                      </th>
                      <th>
                        Cambio con
                      </th>
                    </thead>
                    <tbody>
                      <template v-for="(prod,index) in sale.products" :key="prod.id">
                        <tr>
                          <td>
                            <input type="checkbox" v-model="prod.refundP" :id="prod.id" :name="prod.description" :value="prod">
                          </td>
                          <td>
                            {{prod.qnt}} x {{prod.name}}
                          </td>
                          <td>
                            <AutocompleteSearch :prod="prod" :index="index" @showModal="showOutOfStockAlert"/>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-dark" data-bs-dismiss="modal" type="button" v-on:click="toggleModal()">
            Annulla
          </button>
          <button class="btn btn-success" id="print" type="button" v-if="sale.salespoint !== 'ONLINE'" @click="doRefund()">
            Stampa documento di reso
          </button>
          <button class="btn btn-success" id="print" type="button" @click="doChange()">
            Effettua cambio
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-sm btn-dark mb-2" v-on:click="toggleModal()">
    Reso/Cambio
  </button>
</template>

<script>
import AutocompleteSearch from "@/components/AutocompleteSearch";
export default {
  name: "RefundAlert",
  props: {
    visible: Boolean,
    sale: Object
  },
  components : {
    AutocompleteSearch
  },
  data() {
    return {
      showAlertRefund:false,
      boxresults : false,//per aprire e chiudere autocomplete
      open: this.visible,//apre o chiude la modale
      productsSelect : [], //prodotti che appaiono nella select
      search : "" //termine per cercare prodotto per cambio dalla select
    }
  },
  beforeMount() {
    //filtro i products con price maggiore di zero perché altrimenti index sopra mi dà undefined
    // per i prodotti che non posso cambiare perché negativi
    this.sale.products = this.sale.products.filter(prod => prod.price > 0);
  },
  computed: {
    idsale(){
      return this.$store.getters.getIdSale;
    },
    products() {
      return this.$store.getters.getProducts;
    },
    prodsChange(){
      return this.$store.getters.getProdsChange;
    }
  },
  methods: {
    showOutOfStockAlert() {
      this.showAlertRefund = true;
    },
    //prende i prodotti di cui voglio fare lo scontrino di reso
    doRefund() {
      let total = 0;
      let date = this.sale.localtime.split(" ");
      let fulldate = date[0].replaceAll("/", "");

      const prodsRefund = this.sale.products.filter(prodotto => prodotto.refundP);
      if(prodsRefund.length) {
        this.toggleModal();
        for (let i = 0; i < prodsRefund.length; i++) {
          this.$store.dispatch('addProduct', {
            id: prodsRefund[i].id,
            idcategory: prodsRefund[i].idcategory,
            description: prodsRefund[i].name,
            price: prodsRefund[i].price,
            tax: prodsRefund[i].tax,
            type: prodsRefund[i].type,
            qnt: prodsRefund[i].qnt,
            variant: prodsRefund[i].variant,
          });
        }
        //setto l'id della vendita nello stato (utile per reso e cambio)
        this.$store.commit("setIdSale", this.sale.id);
        this.$store.dispatch("calculateTotal", null);

        //stampa scontrino di reso
        let data_to_send = `<printerFiscalReceipt>
                          <printRecMessage operator="1" message="REFUND ${this.sale.zRepNumber} ${this.sale.number} ${fulldate} ${localStorage.getItem("idCashier")}" messageType="4" />
                          <beginFiscalReceipt operator="1" />`;

        for (let i = 0; i < prodsRefund.length; i++) {
          let department = localStorage.getItem("dep");
          //IN PRODUZIONE <printRecRefund
          data_to_send += `<printRecItem operator="1" description="${prodsRefund[i].name}" quantity="${prodsRefund[i].qnt}" unitPrice="${prodsRefund[i].price}" department="${department}" justification="1" />`;
          total = total + prodsRefund[i].price;
        }

        data_to_send += `<printRecSubtotal  operator="1" option="0" />
                        <printRecTotal operator="1" description="CONTANTI" payment="${total}" paymentType="0" index="1" justification="2" />
                        <endFiscalReceipt operator="1" />
                    </printerFiscalReceipt>`;

        this.$store.dispatch("sendData", {xml : data_to_send, type : "refund", idsale : this.idSale});
        this.toggleModal();
      }else{
        alert("Attenzione: devi selezionare almeno un prodotto da rendere.");
      }
    },

    doChange() {
      //associa il prodotto della select al prodotto con la checkbox attiva
      //let selectedCheck = this.sale.products.filter(prodotto => prodotto.refundP);
      //questo controllo permette di rendere più prodotti anche se seleziono solo 1 prodotto nella select
      let products = this.sale.products;
      if (this.prodsChange.length) {
        this.$store.commit("setIdSale", this.sale.id);//setto l'idsale della vendita fatta da cambiare
        this.$store.commit('setExChange', true);

        for (let i = 0; i < products.length; i++) {
          //array ausilizario copia di prodsreceipt per il controllo dele quantità del prodotto da cambiare nello scontrino
          //lo scopo è quello di non far cambiare più prodotti di quelli che ho acquistato
         if(products[i].refundP && this.prodsChange[i] !== undefined) {
           let stock = 0;
           if(this.prodsChange[i]) {
             if(Array.isArray(this.prodsChange[i].stock)){
                stock = this.findMin(this.prodsChange[i].stock);
             }else{
               stock = this.prodsChange[i].stock;
             }
           }
           //products[i].refundP serve per capire quali prod hanno la checkbox attiva
           //this.$store.dispatch('setQntChange',{id: products[i].id, qnt: products[i].qnt});
           //this.$store.dispatch('setQntChange',{id: this.prodsChange[i].id, qnt: this.prodsChange[i].qnt, stock: stock });
           this.$store.dispatch('addProduct', {
              id: products[i].id,
              description: products[i].name,
              price: -products[i].price,
              tax: products[i].tax,
              type: products[i].type,
              qnt:  products[i].qnt,
              beforeqnt : products[i].qnt,
              change: false,
            });
            this.$store.dispatch('addProduct', {
              //per accedere al prodotto della select corrispondente alla checkbox
              id: this.prodsChange[i].id,
              description: this.prodsChange[i].name,
              price: this.prodsChange[i].discountprice || this.prodsChange[i].price,
              tax: this.prodsChange[i].tax,
              type: this.prodsChange[i].type,
              qnt: this.prodsChange[i].qnt,//la quantità è quella del prodotto iniziale
              beforeqnt : products[i].qnt,
              change: true,
              stock: stock,
            });
          }
          this.$store.dispatch("calculateTotal");
          this.toggleModal();
          //chiudo la modale delle vendite
          this.$store.commit("setSellsAlert", false);
          //this.$store.commit('emptyReceipt');
        }
      } else {
        alert("Attenzione: devi selezionare almeno un prodotto con cui effettuare il cambio.");
        //svuoto l'array delle checkbox? e lo scontrino
        this.$store.commit('emptyReceipt');
      }
    },
    toggleModal() {
      this.open = !this.open
    },
    findMin(array){
      let min = array[0];
      for(let i = 0; i < array.length; i++){
        if(array[i] < min){
          min = array[i];
        }
      }
      return min;
    }
  }
}
</script>

<style scoped>

.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.table th{
  text-align: center;
}

.boxresults {
  padding: 10px;
  overflow-y: scroll;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
}

.boxresults ul {
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #ffff;
  list-style-type: none;
  width: 100%;
  float: left;
}

.boxresults ul li {
  padding: 8px 0 8px 0;
  width: 100%;
  border-top: 1px solid #dedede;
}

.boxresults ul li a {
  color: #000;
}

</style>