<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Scontrino</h5>
          <button v-on:click="toggleModal()" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-12">
                  <h5 class="title-modal">Tipo pagamento</h5>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="ck-button">
                        <label>
                          <input type="checkbox" name="payment" v-model="card" v-on:click="paymentType('card')"><span>Carta</span>
                        </label>
                      </div>
                    </div>
                    <input type="text" class="form-control" v-model="cardValue" readonly>
                    <div class="input-group-append">
                      <span class="input-group-text">€</span>
                    </div>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="ck-button">
                        <label>
                          <input type="checkbox" name="payment" v-model="bancomat" v-on:click="paymentType('bancomat')"><span>Bancomat</span>
                        </label>
                      </div>
                    </div>
                    <input type="text" class="form-control" v-model="bancomatValue" readonly>
                    <div class="input-group-append">
                      <span class="input-group-text">€</span>
                    </div>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="ck-button">
                        <label>
                          <input type="checkbox" name="payment" v-model="cash" v-on:click="paymentType('cash')"><span>Contanti</span>
                        </label>
                      </div>
                    </div>
                    <input type="text" class="form-control" v-model="cashValue" readonly>
                    <div class="input-group-append">
                      <span class="input-group-text">€</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <h5 class="title-modal">Voucher</h5>
                  <!--<div class="form-check mb-2">
                    <input class="form-check-input" checked="checked" name="typeDiscount" type="radio" value="amount"
                           v-model="selOptionDiscount">
                    <label class="form-check-label" for="amount">
                      Importo
                    </label>
                  </div>
                  <div class="form-check mb-2">
                    <input class="form-check-input" name="typeDiscount" type="radio" value="percentage"
                           v-model="selOptionDiscount">
                    <label class="form-check-label" for="percentage">
                      Percentule
                    </label>
                  </div>
                  <div class="input-group mt-3 mb-3">
                    <input v-model="inputDiscount" type="text" class="form-control" placeholder="Inserisci sconto">
                    <div class="input-group-append">
                      <button class="btn btn-outline-danger" type="button" v-on:click="resetDiscount()">
                        Reset
                      </button>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-dark" type="button" v-on:click="applyDiscount()">
                        Applica
                      </button>
                    </div>
                  </div>-->
                  <div class="input-group mt-3 mb-3">
                    <input v-model="inputVoucher" type="text" class="form-control" placeholder="Inserisci voucher">
                    <div class="input-group-append">
                      <button class="btn btn-outline-danger" type="button" v-on:click="resetDiscount()">
                        Reset
                      </button>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-dark" type="button" :disabled="disableApply" v-on:click="applyVoucher()">
                        Applica
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <h5 class="title-modal">Totali</h5>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-check mb-2">
                    <input class="form-check-input" value="on" name="manageStock" type="checkbox" id="manageStock"
                           v-model="manageStock" checked="checked">
                    <label class="form-check-label" v-on:click="scaricaMagazzino()" for="manageStock">
                      Scarica magazzino
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-12">
                  <!--controllo se ho il totale dopo lo sconto, se c'è lo sostituisco senno lascio il totale originale -->
                  <h4>Totale: <span id="tot_mod">{{ this.total ? formatPrice(this.total) : formatPrice(this.total) || ' €' }} €</span>
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <h4>Resto: <span id="change">{{ formatPrice(this.change) + ' €' || '0 €' }}</span></h4>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="keyboard">
                <div class="input-group mb-3">
                  <input type="text" class="output form-control text-end" :value="result" readonly>
                </div>
                <div class="btn-group-vertical">
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(7)">7</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(8)">8</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(9)">9</button>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(4)">4</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(5)">5</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(6)">6</button>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(1)">1</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(2)">2</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(3)">3</button>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput('.')">.</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(0)">0</button>
                    <button class="btn btn-canc btn-light btn-calc" type="button" v-on:click="clearResult()">C</button>
                  </div>
                </div>
              </div>
              <div class="bottomcalc">
                <button v-show="cash" class="btn btn-success resto mt-1 mb-1" v-on:click="calculateChange()">
                  Calcola resto
                </button>
                <button class="btn btn-dark w-100 mt-1" v-on:click="insertInput()">
                  Inserisci valore
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button class="btn btn-outline-dark" type="button" v-on:click="toggleModal()">Annulla
          </button>
          <button class="btn btn-success" type="button" v-on:click="handleReceipt()">
            Stampa
          </button>
        </div>
      </div>
    </div>
  </div>
  <button v-on:click="toggleModal()" class="scontrino float-start btn btn-large btn-dark">Scontrino</button>
</template>

<script>

import axios from "axios";

export default {
  name: "ReceiptAlert",
  data() {
    return {
      disableApply: false,
      manageStock: true,
      open: false,
      result: 0, //tastierino
      card : false,
      cardValue : 0,
      bancomat : false,
      bancomatValue : 0,
      cash : false,
      cashValue : 0,
      selOptionDiscount: "amount",
      discount: 0,
      inputDiscount: "",
      //voucher in input nella modale di stampa dello scontrino
      inputVoucher : "",
      resultsPos: null,
      timestamp: null,
      voucherDiscount: 0,
      //si popola dopo aver generato il voucher dopo il cambio
      voucherCode : ""
    }
  },
  computed: {
    total() {
      return this.$store.getters.getTotal;
    },
    change() {
      return this.$store.getters.getChange;
    },
    exchange() {
      return this.$store.getters.getExChange;
    },
    prodsReceipt() {
      return this.$store.getters.getProdsReceipt;
    }
  },
  methods: {
    printReceipt(timestamp) {
      //verifico che ci siano prodotti nello scontrino
      if (this.prodsReceipt.length) {
        //verifico che sia selezionato un metodo di pagamento
        if (this.card || this.bancomat || this.cash) {
          //posso anche avere il totale minore di 0 se è un cambio con voucher
          if((this.total < 0 && (parseFloat(this.cardValue) + parseFloat(this.bancomatValue) + parseFloat(this.cashValue)) === 0)
              || (this.total >= 0 && (parseFloat(this.cardValue) + parseFloat(this.bancomatValue) + parseFloat(this.cashValue)) === this.total)) {
            this.open = false;
            const receipt = this.$store.getters.getProdsReceipt;
            let data_to_send = '<printerFiscalReceipt><beginFiscalReceipt operator="1" />';
            for (let i = 0; i < receipt.length; i++) {
              //per la tax
              let dep = localStorage.getItem('dep');
              if (dep) {
                if (receipt[i].price >= 0) {
                  data_to_send += `<printRecItem operator="1" description="${receipt[i].description}" quantity="${receipt[i].qnt}" unitPrice="${receipt[i].price}" department="${dep}" justification="1" />`;
                } else {
                  //se c'é un prodotto negativo allora è un cambio
                  this.$store.commit("setExChange", true);
                  data_to_send += `<printRecItemVoid operator="1" description="${receipt[i].description}" quantity="${receipt[i].qnt}" unitPrice="${receipt[i].price * -1}" department="${dep}" justification="1" />`;
                }
              } else {
                alert("Attenzione: prodotto con reparto non mappato.");
              }
            }

            if (this.total < 0) {
              let depVoucher = localStorage.getItem("dep");
              this.$store.commit('setTotal', 0);
              data_to_send += `<printRecItem operator="1" description="VOUCHER ${this.voucherCode}" quantity="1" unitPrice="${this.voucherDiscount}" department="${depVoucher}" justification="1" />`;
            }

            if (this.discount) {
              data_to_send += `<printRecSubtotalAdjustment operator="1" description="Sconto" adjustmentType="1" amount="${this.discount}" justification="2" />`;
            } else {
              data_to_send += `<printRecSubtotal operator="1" option="1" />`;
            }

            if (this.card) {
              data_to_send += `<printRecTotal operator="1" description="CARTA" payment="${this.cardValue}" paymentType="2" index="1" justification="1" />`;
            }
            if (this.bancomat) {
              data_to_send += `<printRecTotal operator="1" description="CARTA" payment="${this.bancomatValue}" paymentType="2" index="1" justification="1" />`;
            }
            if (this.cash) {
              data_to_send += `<printRecTotal operator="1" description="CONTANTI" payment="${parseFloat(this.cashValue) + parseFloat(this.change)}" paymentType="0" index="1" justification="1" />`;
            }
            /*
              switch (this.selectedPayment) {
                case "carta":
                  data_to_send += `<printRecTotal operator="1" description="CARTA" payment="${this.total}" paymentType="2" index="1" justification="1" />`;
                  break;
                case "bancomat":
                  data_to_send += `<printRecTotal operator="1" description="BANCOMAT" payment="${this.total}" paymentType="2" index="1" justification="1" />`;
                  break;
                case "contanti":
                  data_to_send += `<printRecTotal operator="1" description="CONTANTI" payment="${parseFloat(this.total) + parseFloat(this.change)}" paymentType="0" index="1" justification="1" />`;
                  break;
                default:
                  break;
              }
               */

            data_to_send += `<printNormal operator="1" font="1" data="" />
                     <printRecMessage  operator="1" messageType="3" index="3" font="4" message="Arrivederci e grazie" />
                     <printNormal operator="1" font="1" data="" />
                     <printBarCode operator="1" position="901" width="2" height="66" hRIPosition="3" hRIFont="C" codeType="CODE39" code="${timestamp}" />
                     <endFiscalReceipt operator="1" />
                     </printerFiscalReceipt>`;

            this.$store.dispatch("sendData", {
              xml: data_to_send,
              type: "sale",
              timestamp: timestamp,
              manageStock: this.manageStock,
              payments: {
                "card" : {"active" : this.card, "value" : parseFloat(this.cardValue)},
                "bancomat" : {"active" : this.bancomat, "value" : parseFloat(this.bancomatValue)},
                "cash" : {"active" : this.cash, "value" : parseFloat(this.cashValue)}},
              discount: this.discount,
              change: this.change,
              voucher: this.inputVoucher,
              automaticVoucher: this.voucherCode, //codice voucher automatico per cambio
              total : this.total
            });
            this.result = 0;
            this.voucherCode = "";
            this.resetDiscount();
          }else{
            alert("Attenzione: il totale dei metodi di pagamento non combacia con il totale dello scontrino.");
          }
        }else{
          alert("Attenzione: devi selezionare un metodo di pagamento.");
        }
      }
    },
    resetDiscount() {
      this.disableApply = false;
      this.discount = "";
      this.inputVoucher = "";
      this.$store.dispatch("calculateTotal");
    },
    //in base alla modalità di sconto selezionata calcolo il nuovo totale
    applyDiscount() {
      if (this.selOptionDiscount && this.inputDiscount) {
        let newtotal = 0;
        if (this.selOptionDiscount === "amount") {
          newtotal = this.convertFloat(this.total) - this.convertFloat(this.inputDiscount);
          this.discount = this.inputDiscount;
          this.$store.commit("setTotal", newtotal);
        } else if (this.selOptionDiscount === "percentage") {
          newtotal = this.convertFloat(this.total) * (1 - this.convertFloat(this.inputDiscount) / 100);
          this.discount = (this.total / 100) * this.inputDiscount;
          this.$store.commit("setTotal", newtotal);
        }
      }
    },
    handleReceipt(){
      const timestamp = Math.floor(new Date().getTime() / 1000);
      if (this.total < 0) {
        this.voucherDiscount = this.total * -1;
        axios.post("/coupons/generate", {
          "type": "amount",
          "value": this.voucherDiscount,
          "timestampSale": timestamp,
          "idSalesPoint" : localStorage.getItem('idSalesPoint') //idesterno
        }).then((res) => {
          this.voucherCode = res.data.coupon.couponcode;
          this.printReceipt(timestamp);
        }).catch((error) => {
          if (error.code === "400") {
            alert("Attenzione! La richiesta non è formattata nel modo corretto.")
          } else {
            alert(error.message);
          }
        });
      }else{
        this.printReceipt(timestamp);
      }
    },
    applyVoucher(){
      this.$store.commit("setSpinner", true);
      //couponCode
      axios.post("/coupons/check", {
        "couponCode": this.inputVoucher.toString(),
        "total" : this.total.toString()
      }).then((res) => {
        this.$store.commit("setSpinner", false);
        if(this.total > 0) {
          let newtotal = 0;
          if (res.data.type === "amount") {
            newtotal = this.convertFloat(this.total) - this.convertFloat(res.data.value);
            if (newtotal < 0) {
              newtotal = 0;
              this.discount = this.convertFloat(this.total);
            } else {
              this.discount = this.convertFloat(res.data.value);
            }
            this.$store.commit("setTotal", this.roundFloat(newtotal));//arrotondo il nuovo totale
            this.disableApply = true;
            /*
          }else{
            this.inputVoucher = "";
            alert("Il voucher inserito non può essere applicato al totale dello scontrino.");
          }
          */
          } else if (res.data.type === "percentage") {
            newtotal = this.convertFloat(this.total) * (1 - this.convertFloat(res.data.value) / 100);
            if (newtotal >= 0) {
              this.discount = (this.total / 100) * res.data.value;
              this.$store.commit("setTotal", this.roundFloat(newtotal));//arrotondo il nuovo totale
              this.disableApply = true;
            } else {
              this.inputVoucher = "";
              alert("Il voucher inserito non può essere applicato al totale dello scontrino.");
            }
          }
        }else{
          this.inputVoucher = "";
          alert("Il voucher inserito non può essere applicato al totale dello scontrino minore di zero.");
        }
      }).catch((error) => {
        this.$store.commit("setSpinner", false);
        if(error.response.status === 400 ){
            alert("Attenzione! Il voucher inserito non è valido.")
        }else{
          //gestione voucher già utilizzato o non esistente
          alert(error.response.data.error);
        }
      });
    },
    scaricaMagazzino() {
      this.manageStock = !this.manageStock;
    },
    //se clicco contanti abilito il calcola resto altrimenti resta disattivato
    paymentType(type) {
      this.result = 0;
      if (type === "cash") {
        this.cash = !this.cash;
        if(!this.bancomat && !this.card) {
          this.cashValue = this.total > 0 ? this.total : 0;
        }
        //se deseleziono cash
        if(!this.cash){
          this.cashValue = 0;
          if(this.bancomat) {
            this.bancomatValue = this.total;
            this.cardValue = 0;
          }
          if(this.card) {
            this.bancomatValue = 0;
            this.cardValue = this.total > 0 ? this.total : 0;
          }
        }
        if(this.bancomat && this.card){
          //significa che è già stato selezionato anche bancomat
          this.bancomatValue = 0;
          this.cardValue = 0;
          this.bancomat = false;
          this.card = false;
        }
      }
      if(type === "bancomat"){
        this.bancomat = !this.bancomat;
        this.bancomatValue = this.total > 0 ? this.total : 0;
        if(!this.bancomat){
          this.bancomatValue = 0;
        }
        if(this.card){
          //significa che è già stato selezionato anche carta
          this.cardValue = 0;
          this.card = false;
        }
        if(this.cash){
          this.cashValue = 0;
          this.cash = false;
        }
      }
      if(type === "card"){
        this.card = !this.card;
        this.cardValue = this.total > 0 ? this.total : 0;
        if(!this.card){
          this.cardValue = 0;
        }
        if(this.bancomat){
          //significa che è già stato selezionato anche bancomat
          this.bancomatValue = 0;
          this.bancomat = false;
        }
        if(this.cash){
          this.cashValue = 0;
          this.cash = false;
        }
      }
    },
    //apertura/chiusura modale
    toggleModal() {
      this.card = false;
      this.cardValue = 0;
      this.bancomat = false;
      this.bancomatValue = 0;
      this.cash = false;
      this.cashValue = 0;
      this.open = !this.open;
    },
    //tastierino
    addToOutput(value) {
      if (this.result === 0 && value === "."){
        this.result += value.toString();
      }else if(this.result === 0){
        if (value !== 0) {
          this.result = value.toString();
        }
      } else {
        if (this.result.length < 12) {
          this.result += value.toString();
        }
      }
    },
    //resetta il tastierino
    clearResult() {
      this.$store.dispatch("calculateTotal");
      this.$store.commit("setChange", 0);
      this.discount = null;
      this.result = 0;//reset display del tastierino
    },
    calculateChange() {
      //se il totale e il result è maggiore di 0
      if (this.total && this.result && this.result >= this.total) {
        this.$store.commit('setChange', this.result - this.total);
      }
    },
    roundFloat(value){
      return Number(value.toFixed(2));
    },
    convertFloat(num){
      return parseFloat(num.toString().replace(",","."));
    },
    formatPrice(value) {
      return Number(value).toFixed(2).toString().replace(".", ",");
    },
    insertInput(){
      if(this.cash){
        this.cashValue = this.result;
        if(this.bancomatValue) {
          this.bancomatValue = this.bancomatValue - this.cashValue;
        }
        if(this.cardValue) {
          this.cardValue = this.cardValue - this.cashValue;
        }
      }else if(this.bancomat){
        this.bancomatValue = this.result;
      }else if(this.card){
        this.cardValue = this.result;
      }

    }
  },
}
</script>

<style scoped>

.keyboard {
  border: 1px solid #dedede;
}

.keyboard .output {
  border: none;
  border-bottom: 1px solid #dedede;
}

.btn-canc {
  color: red;
}

.btn-calc {
  width: 100%;
  font-size: 45px;
  font-weight: bold;
}

.modal-dialog {
  background-color: white;
  z-index: 1;

}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.scontrino {
  width: 100%;
  border-radius: 0px;
}

.title-modal {
  text-align: left;
  padding: 10px 0 10px 0;
  font-size: 15px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}

label, h4 {
  float: left;
}

input, button {
  border-radius: 0px;
}

.resto {
  width: 100%;
}

.keyboard {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.output {
  font-size: 40px;
  align-items: flex-end;
}

</style>

