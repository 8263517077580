<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Dettaglio vendita</h5>
          <button v-on:click="toggleModal()" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="order-top"></div>
          <div class="order">
            <div class="text-center title-order">Ordine website numero: {{ sale?.idorder }} del
              {{ formatDate(sale?.created_at) }} {{formatTime(sale?.created_at)}}
            </div>
            <div class="row ">
              <div class="col shippingAddress">
                <div class="dati mt-4 mb-4">INDIRIZZO DI SPEDIZIONE:</div>
                <br><br><br>
                <div class="dati"><strong>Nome:</strong> {{ sale?.name }} {{ sale?.surname }}</div>
                <br>
                <div class="dati"><strong>Indirizzo: </strong>{{ sale?.address }}, {{ sale?.numciv }}</div>
                <br>
                <div class="dati"><strong>Città: </strong>{{ sale?.city }} ({{ sale?.prov }}), {{ sale?.cap }}</div>
                <br>
                <div class="dati"><strong>Stato: </strong>{{ sale?.country }}</div>
                <br>
                <div class="dati"><strong>Numero di telefono:</strong> {{ sale?.phone }}</div>
                <br>
              </div>
              <div class="col shippingAddress" v-if="sale?.billingcompany">
                <div class="dati mt-4">INDIRIZZO DI FATTURAZIONE:</div>
                <br><br><br>
                <div class="dati"><strong>Nome:</strong> {{ sale?.name }} {{ sale?.surname }}</div>
                <br>
                <div class="dati"><strong>Indirizzo: </strong>{{ sale?.billingaddress }}, {{ sale?.billingnumciv }}
                </div>
                <br>
                <div class="dati"><strong>Città:</strong> {{ sale?.billingcity }} ({{ sale?.billingprov }}),
                  {{ sale?.billingcap }}
                </div>
                <br>
                <div class="dati"><strong>Stato:</strong> {{ sale?.billingcountry }}</div>
                <br>
                <div class="dati"><strong>Azienda:</strong> {{ sale?.billingcompany }}</div>
                <br>
                <div class="dati"><strong>VAT:</strong> {{ sale?.billingvatnumber }}</div>
                <br>
                <div class="dati"><strong>PEC: </strong>{{ sale?.billingpec }}</div>
                <br>
                <div class="dati"><strong>Telefono:</strong> {{ sale?.billingphone }}</div>
              </div>
            </div>
            <div class="row mt-3 orderdetails">
              <div class="dati mt-3"><strong>DETTAGLIO ORDINE</strong></div><br>
              <div class="sales" v-if="sale?.details.length">
                <div class="table-responsive">
                  <table class="table table-light">
                    <thead >
                    <th>Articolo</th>
                    <th>Qnt</th>
                    <th>Subtotale</th>
                    </thead>
                    <tbody>
                    <tr v-for="detail in sale.details">
                      <td>
                        {{ detail.productname }}
                      </td>
                      <td>
                        {{ detail.qnt }}
                      </td>
                      <td>
                        {{formatPrice(detail.unitprice)}} €
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else class="alert alert-info text-center">
                Non ci sono articoli
              </div>
            </div>
            <div class="row mt-3 total">
              <div class="dati"><strong>Spese di spedizione:</strong> {{formatPrice(sale?.shippingprice)}} €</div>
              <div class="dati"><strong>Totale ordine:</strong> {{formatPrice(sale?.total)}} €</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-dark" type="button" v-on:click="toggleModal()">Indietro</button>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-sm btn-success mb-2" v-on:click="toggleModal()">
    Vedi
  </button>
</template>

<script>
import axios from "axios";

export default {
  name: "ShowOrder",
  props: {
    visible: Boolean,
    idSale: String
  },
  data() {
    return {
      open: this.visible,
      sale: null
    }
  },
  methods: {
    formatPrice(value) {
      return Number(value).toFixed(2).toString().replace(".", ",");
    },
    formatDate(date) {
      if (!date) return ''; // Handle null or undefined dates
      const formattedDate = date.replace(/-/g, '/').slice(0, 10);
      return formattedDate;
    },
    formatTime(dateTime) {
      if (!dateTime) return ''; // Handle null or undefined dateTime
      const time = dateTime.split('T')[1].split('.')[0];
      return time;
    },
    toggleModal() {
      this.open = !this.open
      this.getSale();
    },
    async getSale() {
      this.$store.commit("setSpinner", true);
      axios.get("/sales/" + this.idSale).then((response) => {
        this.sale = response.data;
        this.$store.commit("setSpinner", false);
      }).catch((error) => {
        this.$store.commit("setSpinner", false);
        console.log(error);
      });
    }
  },
}
</script>

<style scoped>
.table-responsive{
  font-weight: normal;
  text-align: start;
}
.orderdetails{
  border: solid lightgray 2px;
  background-color: #ffffff;
  height:min-content;
  text-align: center;
  vertical-align: middle;
}
/*.total{
  border: solid lightgray 2px;
  background-color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
}*/
.shippingAddress{
  border: solid lightgray 2px;
  background-color: #ffffff;
}
.dati {
  float: left;
  display: block;
}

.modal-dialog {
  background-color: white;
  z-index: 1;

}
.modal-body{
  overflow: auto;
}
.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.title-order {
  font-size: 20px;
}

</style>