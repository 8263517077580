<template>
  <div class="container">
    <div class="row" v-show="$store.state.showAlertStock">
      <div class="alert alert-danger alert-dismissible">
        <strong>Attenzione</strong> {{ $store.state.message }}
        <button v-on:click="$store.commit('setShowAlertStock', false)" type="button" class="btn-close"></button>
      </div>
    </div>
    <!--div class="row block-cat mb-3">
      <template v-if="categories.length">
        <div class="col-xl-3 col-lg-4 col-md-6 mb-3" v-for="category in categories" :key="category.id">
          <button type="button" class="btn btn-sm btn-category mb-2" :class="{'btn-dark' : (idCategory === category.id), 'btn-outline-dark' : (idCategory !== category.id)}" @click="getProducts(category.id)">
            <img v-if="category.imageUrl" class="img-cat" :src="base + '/' + category.imageUrl">
            <img v-else class="img-cat" :src="'images/placeholder.jpg'" alt="placeholder category">
            <br>
            {{ category.description }}
          </button>
        </div>
      </template>
      <template>
        <div class="col-lg-12">
          <div class="alert alert-info mb-0">Non ci sono categorie inserite</div>
        </div>
      </template>
    </div-->
    <div class="row block-prods">
      <template v-if="products.length">
        <div class="col-xl-3 col-lg-4 col-md-6 mb-3" v-for="product in products" :key="product.id">
          <div class="card" @click="(product.type === 'package') ? showProductDetails(product) : addProdReceipt(product)">
            <div class="card-body">
              <img v-if="product.image" class="img-product" :src="product.image" alt=""/>
              <img v-else class="img-product" :src="'/images/placeholder.png'" alt=""/>
              <h5 class="card-title" v-if="product.description">{{ product.description }}</h5>
              <h5 class="card-title" v-else>{{ product.name }}</h5>
              <p class="card-prezzo" v-if="product.discountprice">
                <del>{{formatPrice(product.price)}}</del> € - {{formatPrice(product.discountprice)}} €
              </p>
              <p class="card-prezzo" v-else>
                {{formatPrice(product.price)}} €
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-12">
          <div class="alert alert-info mb-0">Non ci sono prodotti inseriti</div>
        </div>
      </template>
    </div>
    <!-- details prod dialog -->
    <div class="modal" v-if="product" :class="{show: showModalDetails}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dettaglio prodotto</h5>
            <button v-on:click="$store.commit('setShowModalDetails', false)" type="button" class="btn-close"/>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-lg-12" style="text-align: left;">
                  <h4>Nome</h4>
                  <p>{{ product.description }}</p>
                  <h4>Prezzo</h4>
                  <p>{{ formatPrice(product.price) }} €</p>
                </div>
              </div>
              <div class="row" v-if="product.type === 'package'">
                <div class="col-lg-12" style="text-align: left;">
                  <h4>Package</h4>
                  <template v-for="(variation,index) in product.variations" :key="index">
                    <button type="button" class="btn btn-light btn-border mb-2 mr-2" v-bind:class="{'btn-outline-danger':(variation.subproducts.filter((el) => {return el.stock <= 0}).length), 'btn-scanned': product.barcode && barcodeString === product.barcode.sku}"
                            @click="addProdReceipt(product, variation)">
                      <!--prendo solo la lettera finale per le taglie-->
                      {{ variation.name }}<br>
                      <p v-if="variation.subproducts.length == 2"> Giacenze: {{ variation.subproducts[0].stock }} - {{ variation.subproducts[1].stock }}</p>
                      <p v-else>Giacenze: {{variation.subproducts[0].stock}}</p>
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BASE_URL} from "@/constants/constants";

export default {
  data() {
    return {
      max_item_desc_len: 17,
      base: BASE_URL
    };
  },
  computed: {
    products() {
      return this.$store.getters.getProducts
    },
    //productDetail
    product(){
      return this.$store.getters.getProductDetail
    },
    showModalDetails(){
      return this.$store.getters.getShowModalDetails
    },
    //ritorna il codice a barre dal laser scanner
    barcodeString(){
      return this.$store.getters.getSearchString
    },
    /* categories() {
       return this.$store.getters.getCategories;
     },
     idCategory() {
       return this.$store.getters.getIdCategory;
     }*/
  },
  beforeMount() {
    this.$store.dispatch("getProducts");
  },
  methods: {
    //aggiunge allo scontrino il prodotto semplice o la variante di prodotto dei sottoprodotti
    addProdReceipt(product, variant = null) {
      let nameVar = "";
      //se la taglia è unica non concateno il nome altrimenti lo concateno
      if(variant){
        nameVar = product.description + ' ' + variant.name;
      }
      let prodname = variant ? nameVar : product.description;
      let price = product.discountprice || product.price;
      this.$store.commit('setShowModalDetails', false);
      this.$store.commit('setShowAlertStock',false);
      //controllo le giacenze
      if(variant && variant.subproducts[0].stock <=0){
        this.$store.commit('setShowAlertStock',true);
        this.$store.commit('setMessage',"Il prodotto non ha disponibilità a magazzino.");
      }else if(product.stock <= 0){
        this.$store.commit('setShowAlertStock',true);
        this.$store.commit('setMessage',"Il prodotto non ha disponibilità a magazzino.");
      }
      //forzo lunghezza nome per display
      if (prodname.length < this.max_item_desc_len) {
        //forzare la lunghezza di max_item_desc_len mettendo i spazi se necessario
        prodname = prodname + "                 "; // lunghezza massima della stampante è 17 caratteri senza la quantità
        prodname = prodname.substring(0, this.max_item_desc_len);
      } else {
        prodname = prodname.slice(0, this.max_item_desc_len); // Troncare la stringa
      }

      let stock;
      if(variant){
        //se ha sub
        stock = this.findMinStockSub(variant.subproducts);
      }else{
        stock = product.stock;
      }
      //aggiuge i prodotti nello scontrino
      this.$store.dispatch('addProduct', {
        id: variant ? variant.idpackage : product.id,
        //idcategory: prodotto.idcategory,
        description: variant ? nameVar : product.description,
        price: product.discountprice || product.price,
        tax: product.tax,
        type: product.type,
        qnt: 1,
        stock: stock, //inserisco sempre lo stock dell'oggetto
      });
      this.$store.dispatch("calculateTotal");
      //prendo la quantità totale del prodotto che c'é
      let receipt = this.$store.getters.getProdsReceipt;
      let prodFiltered = receipt.filter(prod => variant ? prod.id === variant.idpackage : prod.id === product.id);
      //stampa su display il nome e il prezzo del prodotto
      let secondLine = `${this.formatPrice(price * prodFiltered[0].qnt)} EURO`;
      let strpad = this.addPadString(secondLine, 20);
      let messageDisplay = `<printerCommand><displayText operator="1" data="x${prodFiltered[0].qnt} ${prodname}${strpad}${secondLine}" /></printerCommand>`;
      this.$store.dispatch("sendData", {xml: messageDisplay, hidespinner: true});//metto a true perché non mi devo aspettare results dalla stampante
    },
    addPadString(string, numtotalpad) {
      let strpad = "";
      if (string.length < 20) {
        for (let i = 0; i < numtotalpad - string.length; i++) {
          strpad += " ";
        }
        return strpad;
      } else {
        return "";
      }
    },
    //prende il prodotto per vederne i dettagli nella modale
    showProductDetails(product) {
      this.$store.commit("setProductDetail", product);
      this.$store.commit("setShowModalDetails", true);
    },
    //mostra i prodotti e se è presente la categoria mostra i prodotti di quella categoria
    getProducts() {
      //this.$store.commit('setIdCategory',idcategory);
      this.$store.dispatch("getProducts");
    },
    formatPrice(value) {
      return Number(value).toFixed(2).toString().replace(".", ",");
    },
    findMinStockSub(subproducts){
      let min = subproducts[0].stock;
      for(let i = 0; i < subproducts.length; i++){
        if(subproducts[i].stock < min){
          min = subproducts[i].stock;
        }
      }
      return min;
    }
  },

};
</script>

<style scoped>
.btn-scanned{
  border: 3px solid red;
}
.block-prods {
  height: 536px;
}

.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.img-product {
  width: 100%;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.img-product-details {
  width: 100%;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.img-product:hover {
  cursor: pointer;
}

.card-title {
  font-size: 15px;
}

.card {
  border-radius: 0;
  margin-bottom: 20px;
  min-height: 400px;
  max-height: 500px;
}

.card-body {
  font-size: 18px;
  font-weight: bold;
  height: min-content;
}

.show {
  display: block;
}
.block-cat, .block-prods {
  padding: 20px 10px 20px 10px;
  border: 1px solid #dedede;
  overflow-y: scroll;
}

.block-cat {
  max-height: 200px;
}

.btn-category {
  text-align: center;
  display: block;
  width: 100%;
  padding: 10px 10px;
  height: 200px;
}

.btn-category:focus {
  background-color: #000;
  color: #ffffff;
}

.img-cat {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.btn-border{
  border: 1px solid #000;
}

.btn-outline-danger{
  border: 1px solid #dd3c4c;
}

.mr-2{
  margin-right: .5rem !important;
}
</style>