<template>
  <div id="cashier">
    <Spinner/>
    <Header/>
    <div class="container-fluid">
      <Home/>
    </div>
  </div>
</template>
<script>

import Header from "@/components/Header";
import Home from "@/views/Home";
import Spinner from "@/components/Spinner";

export default {
  name: 'App',
  components: {
    Spinner,
    Header,
    Home
  }
}
</script>


<style>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

input {
  border-radius: 0px;
}

.modal-xl{
  max-width: 1000px !important;
}
</style>
