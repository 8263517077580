<template>
  <div class="spinner" v-if="spinner">
    <div class="preloader">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  methods: {
    show() {
      this.$store.commit('setSpinner',true);
    },
    hide() {
      this.$store.commit('setSpinner', false);
    }
  },
  data() {
    return {}
  },
  computed: {
    spinner(){
      return this.$store.getters.getSpinner;
    }
  }
}
</script>

<style scoped>
.spinner {
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #e20036; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>