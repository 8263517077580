<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img width="90" src="/images/logo_bikini.png">
      </a>
      <button class="navbar-toggler" type="button" v-on:click="toggleMenu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" :class="{show : menu}">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link text-white" :class="{active : deviceconn, disactive : !deviceconn}" href="#" @click="handleClick('barcodeScanner')">Lettore scanner</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#" @click="handleClick('fullScreen')">Tutto schermo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#" @click="handleClick('openDrawer')">Apri cassetto</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#" @click="handleClick('financial_report')">Report finanziario</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#" @click="handleClick('closeCasher')">Chiusura cassa</a>
          </li>
          <li>
            <SettingsAlert :visible="false"/>
          </li>
        </ul>
      </div>
    </div>

    <!--<div class="btn-group">
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('fullScreen')">
        Tutto schermo
      </button>
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('openDrawer')">
        Apri cassetto
      </button>
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('financial_report')">
        Report finanziario
      </button>
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('closeCasher')">
        Chiusura cassa
      </button>
      <SettingsAlert :visible="false"/>
    </div>-->
  </nav>
</template>

<script>
import SettingsAlert from "@/components/SettingsAlert";

export default {
  name: "Header",
  data() {
    return {
      toggle: false,
    }
  },
  components: {SettingsAlert},
  computed : {
    menu(){
      return this.$store.getters.getMenu;
    },
    deviceconn(){
      return this.$store.getters.getDeviceConn;
    }
  },
  methods: {
    handleClick(buttonId) {
      var xml = null;
      switch (buttonId) {
        case "fullScreen":{
          const elem = document.getElementById("app");
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
          }
        }break;
        case "openDrawer": {
          xml = '<printerCommand><openDrawer operator="1" /></printerCommand>';
          this.$store.dispatch("sendData", {xml : xml, async : true});
        }break;
        case "barcodeScanner": {
          this.$store.dispatch("sendDataScanner");
        }break;
        case "financial_report": {
          if (confirm("Vuoi procedere con la stampa del report finanziario?")) {
            xml = '<printerFiscalReport> <displayText operator="1" data="Report finanziario"/> <printXReport operator="1" timeout="10000" /> </printerFiscalReport>';
            this.$store.dispatch("sendData", {xml : xml, async : true});
          }
        }break;
        case "closeCasher": {
          if (confirm("Vuoi procedere con la chiusura di cassa giornaliera?")) {
            xml = '<printerFiscalReport><displayText operator="1" data="Chiusura di cassa"/><printZReport operator="1" timeout="10000" /> </printerFiscalReport>';
            this.$store.dispatch("sendData", {xml : xml, async : true});
          }
        }break;
        default:
          break;
      }
    },
    toggleMenu(){
      this.$store.commit("setMenu",!this.menu);
    }
  }
}
</script>

<style scoped>

.navbar-brand{
  color: #ffffff;
}

.modal-dialog {
  background-color: white;
  z-index: 1;

}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

h1 {
  margin-bottom: 0px;
}
</style>