<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Impostazioni</h5>
          <button @click="toggleModal()" type="button" class="btn-close" aria-label="Close"/>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>idSalesPoint</strong></label>
                  <input class="form-control" type="text" v-model="idSalesPoint"/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>IP Cassa</strong></label>
                  <input class="form-control" type="text" v-model="ipCashier"/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>Matricola Cassa</strong></label>
                  <input class="form-control" type="text" v-model="idCashier"/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>Indice reparto</strong></label>
                  <input class="form-control" type="text" v-model="dep"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-end">
                <button type="submit" class="btn btn-success">Salva impostazioni</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <a class="nav-link text-white" href="#" @click="toggleModal()">Impostazioni</a>
</template>

<script>
export default {
  name: "SettingsDefault",
  data() {
    return {
      dep : null,
      voucher : null,
     // etoken : null,
      idSalesPoint: null,
      ipCashier: null,
      idCashier: null,
      idDepartment: null,
      open : false
    }
  },
  beforeMount() {
    // Recupera il valore della form dal local storage
    this.idSalesPoint  = localStorage.getItem('idSalesPoint');
    this.ipCashier = localStorage.getItem('ipCashier');
    this.idCashier = localStorage.getItem('idCashier');
    this.dep = localStorage.getItem('dep');
    this.voucher = localStorage.getItem('voucher');
  },
  methods: {
    toggleModal() {
      this.open = !this.open;
    },
    submitForm() {
      localStorage.setItem('idSalesPoint', this.idSalesPoint);
      localStorage.setItem('ipCashier', this.ipCashier);
      localStorage.setItem('idCashier', this.idCashier.toUpperCase());
      localStorage.setItem('dep', this.dep);
      localStorage.setItem('voucher', this.voucher);
      this.toggleModal();
      window.location.reload();
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.form-group {
  margin-bottom: 10px;
}

.icons :hover {
  background-color: transparent;
  border: none
}

input {
  border-radius: 0px;
}

label {
  margin-top: 5px;
  float: left
}

</style>