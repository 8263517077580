<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mb-3">
        <form @submit.prevent="searchProducts">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Cerca prodotti" v-model="searchstring">
            <div class="input-group-append">
              <button class="btn btn-danger" type="button" @click="deleteSearch">
                Elimina
              </button>
            </div>
            <div class="input-group-append">
              <button class="btn btn-dark" type="submit">Cerca</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="text-end">
          <TransfersAlert/>
          <SellsAlert/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransfersAlert from "@/components/TransfersAlert";
import SellsAlert from "@/components/SellsAlert";

export default {
  name: "BoxButton",
  components: {TransfersAlert,SellsAlert},
  data() {
    return {

    }
  },
  computed: {
    idCategory() {
      return this.$store.getters.getIdCategory;
    },
    searchstring : {
      get () {
        return this.$store.getters.getSearchString;
      },
      set (value) {
        this.$store.commit('setSearchString', value)
      }

    }
  },
  mounted(){
    this.$store.dispatch("createDeviceScanner");
  },
  methods:{
    deleteSearch(){
      this.$store.commit("setSearchString","");
      this.$store.dispatch("getProducts",{"idcategory" : this.idCategory});
    },
    searchProducts() {
      if(this.searchstring.length) {
        this.$store.dispatch("getProducts");
      }
    }
  },
}
</script>

<style scoped>

.container {
  height: max-content;
  padding: 0;
  margin-top: 10px;
}

</style>